html, body, #root {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

body {
    animation: fade-in 1s ease;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

.pattern {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
}

#banner-container {
    clip-path: url(#cache);
}

#banner-anim{
    position: absolute;
}

@media only screen and (max-width: 700px) {
    #banner-anim{
        position: absolute;
        height: 100%;
    }
}


#banner-white {
    fill: none;
    opacity: 0.15;
    stroke: #fff;
    stroke-width: 12;
    stroke-miterlimit: 10;
    animation: show 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

#banner-blue {
    fill: none;
    opacity: 0.15;
    stroke: rgb(27, 103, 107);
    stroke-width: 12;
    stroke-miterlimit: 10;
    animation: show 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

#banner-light-blue {
    fill: none;
    opacity: 0.15;
    stroke: rgb(27, 103, 107);
    stroke-width: 6;
    stroke-miterlimit: 10;
    stroke-dasharray: 200;
    stroke-dashoffset: 800;
    animation: draw 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.social-container{
    display: flex;
    justify-content: space-between;
    width: 90px;
}

.social-container svg{
    transition: all 300ms;
}
.social-container svg:hover{
    fill: #fff;
    transform: scale(2);
}

.head-container img{
    transition: all 999ms;
}


.head-container img:hover{
    transform: rotate(360deg);
    transition-timing-function: cubic-bezier(0.46, 0.95, 0.88, 1.05);
}



@keyframes draw {
    to {
        stroke-dashoffset: 0;
    }
}

@keyframes show {
    0% {
        opacity: 0.15;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 0.15;
    }
}


@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}